import { inject }                            from 'aurelia-framework';
import { PLATFORM }                          from 'aurelia-pal';
import { BaseFormViewModel }                 from 'base-form-view-model';
import { FormSchema }                        from 'modules/bituminous-mixtures/mixtures/approved-working-formulas/form-schema';
import { ApprovedWorkingFormulasRepository } from 'modules/bituminous-mixtures/mixtures/approved-working-formulas/services/repository';
import { AppContainer }                      from 'resources/services/app-container';

@inject(AppContainer, ApprovedWorkingFormulasRepository, FormSchema)
export class EditManufacturingPlantTransposition extends BaseFormViewModel {

    headerTitle = 'form.title.edit-record';
    formId      = 'bituminous-mixtures.mixtures.approved-working-formulas.edition-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('bituminous-mixtures.mixtures.approved-working-formulas.index') && super.can([
            'bituminous-mixtures.mixtures.approved-working-formulas.manage',
            'bituminous-mixtures.mixtures.approved-working-formulas.edit',
        ]);
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/approved-working-formulas/form.html');
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);

        if (this.appContainer.authenticatedUser.isLotClosed || this.model.is_validated) {
            return this.redirectToRoute({
                name:   'bituminous-mixtures.mixtures.approved-working-formulas.view',
                params: params || {},
            });
        }

        this.schema                      = this.formSchema.schema(this);
        this.additionalInformationSchema = this.formSchema.additionalInformationSchema(this);
        this.lotInfoSchema               = this.formSchema.lotInfoSchema(this);
        this.globalSchema                = this.formSchema.globalSchema(this);

        this.fillInfo = this.infoAlertMessage(this.appContainer.i18n.tr('form.message.before-sample-selection'));
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    submit() {
        this.alert = null;

        this.repository
            .update(this.model.id, this.model)
            .then((response) => {
                this.handleResponse(response, 'bituminous-mixtures.mixtures.approved-working-formulas.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData(params) {
        const response = await this.repository.find(params.id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
